<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1 class="font-weight-regular">
                    Cryptocurrency Coins
                </h1>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-center">
                <p class="mt-2">
                    Our browser miners currently support the following coins:
                </p>
            </v-col>
        </v-row>
        <v-row
            v-for="(item, key) in content"
            :key="key">
            <v-col>
                <v-card
                    outlined>
                    <v-card-text>
                        <v-row align="center">
                            <v-col
                                class="text-center"
                                cols="12"
                                sm="auto">
                                <img
                                    width="200px"
                                    :src="getLogo(item.logo)"
                                    :alt="item.coin">
                            </v-col>
                            <v-col>
                                <h2 class="mb-4">
                                    {{item.title}}
                                </h2>
                                <div v-html="item.content">
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="3484124564"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Ad from './../components/ads/GeneralAd';

    export default {
        metaInfo: {
            titleTemplate: 'Cryptocurrency Coins - %s'
        },
        data() {
            return {
                content: [
                    {
                        logo: 'xmr',
                        title: 'Monero (XMR)',
                        content: '<p>Monero is an open-source cryptocurrency created in April 2014 that focuses on privacy and decentralization that runs on Windows, macOS, Linux, Android, and FreeBSD. Monero uses a public ledger to record transactions while new units are created through a process called mining.</p><p class="mb-0">SRC: <a href="https://en.wikipedia.org/wiki/Monero" target="_blank">WIKI</a>﻿</p>'
                    },
                    {
                        logo: 'msr',
                        title: 'Masari (MSR)',
                        content: '<p>Masari (MSR) is a proof of work (PoW) peer-to-peer internet currency based from Monero using the well researched and cryptographically sound RingCT and CryptoNote protocols. This means that every transaction origin, amount, and destination is obfuscated by default.</p><p class="mb-0">SRC: <a href="https://en.bitcoinwiki.org/wiki/Masari" target="_blank">Bitcoin WIKI</a>'
                    },
                    {
                        logo: 'sumo',
                        title: 'Sumokoin (SUMO)',
                        content: '<p>Sumokoin is a privacy cryptocurrency forked from the Monero cryptocurrency officially launched by a team working out of Japan on May 7, 2017. Sumokoin uses Ring Confidential Transactions (RingCT) with a minimum ring size of 12 to ensure Sumokoin user transaction information (sources and amounts) and make the Sumokoin network resistant to blockchain-analysis attacks. The Sumokoin network uses a CryptoNight Proof-of-Work (PoW) consensus mechanism. Every Sumokoin transaction is cryptographically secured using a 26 word mnemonic seed.</p><p class="mb-0">SRC: <a href="https://golden.com/wiki/Sumokoin-PBWGXGW" target="_blank">Golden WIKI</a></p>'
                    },
                    {
                        logo: 'lthn',
                        title: 'Lethean (LTHN)',
                        content: '<p>Lethean is decentralized (peer-2-peer) VPN project with commercial gateway backed by the blockchain. Lethean itself is the fuel and operating currency for this decentralized VPN. It is based on the CryptoNote technology and uses the exclusive CryptoNight v8 algorithm.</p><p>SRC: <a href="https://en.bitcoinwiki.org/wiki/Lethean" target="_blank">Bitcoin WIKI</a></p>'
                    }
                ]
            }
        },
        components: {
            Ad
        }
    }
</script>